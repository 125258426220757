<template>
  <base-dialog v-model="dialog" max-width="900" persistent title="Variáveis">
    <v-container v-if="variables">
      <v-alert type="info" outlined dense>Apenas leitura.</v-alert>
      <div v-for="(value, key) in variables" :key="key" class="box">
        <v-row>
          <v-col cols="6">
            <mf-text-input readonly label="Variável" :value="key" />
          </v-col>
          <v-col cols="6">
            <mf-text-input readonly label="Valor" :value="value" />
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="auto">
          <mf-button color="error" icon="restart_alt" label="Sair" @click="dialog = false"></mf-button>
        </v-col>
      </v-row>
    </v-container>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../../components/atomic-components/atoms/BaseDialog.vue'

export default {
  components: {
    BaseDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    variables: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialog: this.value
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    }
  }
}
</script>
